exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-astrea-index-jsx": () => import("./../../../src/pages/astrea/index.jsx" /* webpackChunkName: "component---src-pages-astrea-index-jsx" */),
  "component---src-pages-astrea-login-jsx": () => import("./../../../src/pages/astrea-login.jsx" /* webpackChunkName: "component---src-pages-astrea-login-jsx" */),
  "component---src-pages-astrea-planos-e-precos-jsx": () => import("./../../../src/pages/astrea/planos-e-precos.jsx" /* webpackChunkName: "component---src-pages-astrea-planos-e-precos-jsx" */),
  "component---src-pages-astrea-register-jsx": () => import("./../../../src/pages/astrea-register.jsx" /* webpackChunkName: "component---src-pages-astrea-register-jsx" */),
  "component---src-pages-aurum-summit-index-jsx": () => import("./../../../src/pages/aurum-summit/index.jsx" /* webpackChunkName: "component---src-pages-aurum-summit-index-jsx" */),
  "component---src-pages-calculadora-de-prazos-processuais-calendario-de-feriados-jsx": () => import("./../../../src/pages/calculadora-de-prazos-processuais/calendario-de-feriados.jsx" /* webpackChunkName: "component---src-pages-calculadora-de-prazos-processuais-calendario-de-feriados-jsx" */),
  "component---src-pages-calculadora-de-prazos-processuais-index-jsx": () => import("./../../../src/pages/calculadora-de-prazos-processuais/index.jsx" /* webpackChunkName: "component---src-pages-calculadora-de-prazos-processuais-index-jsx" */),
  "component---src-pages-calculadora-de-prazos-processuais-themis-calendario-de-feriados-jsx": () => import("./../../../src/pages/calculadora-de-prazos-processuais-themis/calendario-de-feriados.jsx" /* webpackChunkName: "component---src-pages-calculadora-de-prazos-processuais-themis-calendario-de-feriados-jsx" */),
  "component---src-pages-calculadora-de-prazos-processuais-themis-index-jsx": () => import("./../../../src/pages/calculadora-de-prazos-processuais-themis/index.jsx" /* webpackChunkName: "component---src-pages-calculadora-de-prazos-processuais-themis-index-jsx" */),
  "component---src-pages-contato-index-jsx": () => import("./../../../src/pages/contato/index.jsx" /* webpackChunkName: "component---src-pages-contato-index-jsx" */),
  "component---src-pages-depoimentos-index-jsx": () => import("./../../../src/pages/depoimentos/index.jsx" /* webpackChunkName: "component---src-pages-depoimentos-index-jsx" */),
  "component---src-pages-experimente-o-astrea-jsx": () => import("./../../../src/pages/experimente-o-astrea.jsx" /* webpackChunkName: "component---src-pages-experimente-o-astrea-jsx" */),
  "component---src-pages-experimente-o-themis-dj-jsx": () => import("./../../../src/pages/experimente-o-themis-dj.jsx" /* webpackChunkName: "component---src-pages-experimente-o-themis-dj-jsx" */),
  "component---src-pages-experimente-o-themis-ea-jsx": () => import("./../../../src/pages/experimente-o-themis-ea.jsx" /* webpackChunkName: "component---src-pages-experimente-o-themis-ea-jsx" */),
  "component---src-pages-federal-register-jsx": () => import("./../../../src/pages/federal-register.jsx" /* webpackChunkName: "component---src-pages-federal-register-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-quem-somos-jsx": () => import("./../../../src/pages/quem-somos.jsx" /* webpackChunkName: "component---src-pages-quem-somos-jsx" */),
  "component---src-pages-recomende-o-astrea-jsx": () => import("./../../../src/pages/recomende-o-astrea.jsx" /* webpackChunkName: "component---src-pages-recomende-o-astrea-jsx" */),
  "component---src-pages-summit-jsx": () => import("./../../../src/pages/summit.jsx" /* webpackChunkName: "component---src-pages-summit-jsx" */),
  "component---src-pages-themis-obrigado-dj-jsx": () => import("./../../../src/pages/themis-obrigado-dj.jsx" /* webpackChunkName: "component---src-pages-themis-obrigado-dj-jsx" */),
  "component---src-pages-themis-obrigado-ea-jsx": () => import("./../../../src/pages/themis-obrigado-ea.jsx" /* webpackChunkName: "component---src-pages-themis-obrigado-ea-jsx" */),
  "component---src-pages-themis-para-departamento-juridico-index-jsx": () => import("./../../../src/pages/themis-para-departamento-juridico/index.jsx" /* webpackChunkName: "component---src-pages-themis-para-departamento-juridico-index-jsx" */),
  "component---src-pages-themis-para-escritorios-de-advocacia-index-jsx": () => import("./../../../src/pages/themis-para-escritorios-de-advocacia/index.jsx" /* webpackChunkName: "component---src-pages-themis-para-escritorios-de-advocacia-index-jsx" */),
  "component---src-pages-trabalhe-conosco-jsx": () => import("./../../../src/pages/trabalhe-conosco.jsx" /* webpackChunkName: "component---src-pages-trabalhe-conosco-jsx" */)
}

